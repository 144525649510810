document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  alphaModuleMgr.addModuleEventListener('leafletMap', 'map.loaded', (ev) => {
    const mapInfo = ev.mapInfo
    const markers = mapInfo.markers
    if (markers.length === 1) {
      const firstMarker = markers[0]
      const icon = firstMarker?._icon

      // hide marker tooltip by default
      if (icon) {
        setTimeout(() => {
          icon.click()
        }, 50)
      }
    }
  })
})
