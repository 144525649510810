document.addEventListener('DOMContentLoaded', () => {
  const doctolibIframeEl = document.querySelector('iframe[src*="doctolib"]')
  if (doctolibIframeEl) {
    doctolibIframeEl.onload = () => {
      doctolibIframeEl.style.display = 'none'

      setTimeout(() => {
        doctolibIframeEl.style.display = 'block'
      }, 2500)
    }
  }
})
